import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
      am: {
        translation: {
          title: "Ծրագրավորման Դասընթացներ Հայաստանում - Սովորեք կոդավորում զրոյից մինչև Pro",
        },
      },
    en: {
      translation: {
        title: "Programming Courses in Armenia - Learn to Code from Zero to Pro",
      },
    },
    ru: {
      translation: {
        title: "Курсы программирования в Армении – Учись программировать с нуля до профи",
      },
    },
  },
  lng: "am", // Default language
  fallbackLng: "am",
  interpolation: {
    escapeValue: false, // React already prevents XSS
  },
});

export default i18n;
