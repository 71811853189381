import React from 'react'
import Layout from '../layout/Layout'
import { Contact } from '../components/contact/Contact'

export const ContactPage = () => {
  return (
    <>
        <Layout>
            <Contact />
        </Layout>
    </>
  )
}
